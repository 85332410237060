import React from 'react'
import { Modal } from 'react-bootstrap'
import * as $Opportunity from '@services/Opportunity'
import * as $Client from '@services/Client'
import Cnpj from '@components/Form/Cnpj/Cnpj'
import { useMutation, useQuery } from 'react-query'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Select from 'react-select'

type Props = {
  show: boolean
  onClose: () => void
}

const Opportunity: React.FC<Props> = ({ show, onClose }: Props) => {
  const create = useMutation($Opportunity.create)

  const SweetAlert = withReactContent(Swal)

  const { data: clients } = useQuery('clients', $Client.all)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    SweetAlert.fire({
      title: 'Registrando oportunidade',
      text: 'Aguarde enquanto a oportunidade é registrada...',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(event.currentTarget)

    const cnpj = formData.get('cnpj')!.toString().replace(/\D/g, '')

    formData.set('cnpj', cnpj)

    create.mutate(formData, {
      onSuccess: () => {
        SweetAlert.fire({
          title: 'Oportunidade registrada',
          text: 'A oportunidade foi registrada com sucesso.',
        }).then(onClose)
      },
      onError: (e: any) => {
        if (e.response.data?.errors?.length > 0) {
          const html = Object.values(e.response.data.errors).map((value: any) => {
            return value.map((message: string) => `<li>${message}</li>`).join('')
          }).join('')

          SweetAlert.fire({
            title: 'Ocorreu um erro, verifique os campos abaixo e tente novamente.',
            html: `<ul>${html}</ul>`,
            icon: 'error',
          })
        } else if (e.response.data?.error?.length > 0) {
          SweetAlert.fire({
            title: e.response.data.error,
            text: e.response.data.message,
            icon: 'error',
          }).then(onClose)
        } else {
          SweetAlert.fire({
            title: 'Ocorreu um erro, tente novamente.',
            icon: 'error',
          })
        }
      }
    })
  }

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Registrar Oportunidade</Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body className="pb-0">
          <div className="row">
            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="company_name">Razão Social:</label>
              <input type="text" name="company_name" id="company_name" className="form-control" required />
            </div>

            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="trading_name">Nome Fantasia:</label>
              <input type="text" name="trading_name" id="trading_name" className="form-control" required />
            </div>

            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="cnpj">CNPJ:</label>
              <Cnpj type="text" name="cnpj" id="cnpj" className="form-control" required />
            </div>

            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="client_id">Cliente:</label>
              {clients && clients.data.length > 0 && (
                <Select
                  name="client_id"
                  id="client_id"
                  placeholder="Selecione um cliente"
                  options={clients.data.map((client: any) => ({
                    value: client.id,
                    label: client.trading_name
                  }))}
                  required
                />
              )}
            </div>

            <div className="col-12 col-md-4 form-group mb-3">
              <label htmlFor="computers_number">Número de Máquinas:</label>
              <input type="number" name="computers_number" id="computers_number" className="form-control" required />
            </div>

            <div className="col-12 col-md-4 form-group mb-3">
              <label htmlFor="expires_at">Data de vencimento:</label>
              <input type="datetime-local" className="form-control" id="expires_at" name="expires_at" required />
            </div>

            <div className="col-12 col-md-4 form-group mb-3">
              <label htmlFor="notify_renewal_days">Dias para notificar vencimento:</label>
              <input type="number" className="form-control" id="notify_renewal_days" name="notify_renewal_days" defaultValue={15} min={1} required />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">Registrar Oportunidade</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Opportunity
