import styled from 'styled-components'

export const List = styled.div`
  table > tbody > tr > td > .logotipo {
    width: 160px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    padding: 2px;
    border-radius: 6px;

    > a > img {
      width: auto;
      height: auto;
      max-width: calc(100% - 2px);
      max-height: 52px;
    }
  }
`
