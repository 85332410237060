import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '@components/Footer/Footer'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import { Main } from './App.styles'

const App: React.FC<any> = () => {
  return (
    <>
      <Header />

      <Main className="main-content container-fluid">
        <div className="row">
          <div className="col sidebar">
            <Sidebar />
          </div>

          <div className="col outlet">
            <Outlet />
          </div>
        </div>
      </Main>

      <Footer />
    </>
  )
}

export default App
