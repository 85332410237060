import styled from 'styled-components'

export const Card = styled.div`
  width: 360px;
  position: fixed;
  border-radius: 6px 6px 0 0;
  transition: all 0.3s ease-in;
  z-index: 9999;
  bottom: -52px;
  right: 2rem;
  opacity: 0;

  > .card-header {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #eee;

    > .btn-abort {
      outline: none;
      color: #000;
      border: 0;

      &:hover {
        color: var(--danger-color);
      }
    }
  }

  > .card-body {
    padding: 0;
    border-radius: 0;

    > ul {
      padding: 0;
      margin: 0;

      > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding: 0.5rem 1rem;
        list-style: none;
        color: #000;

        > svg {
          color: var(--success-color);
        }
      }
    }
  }

  &.show {
    bottom: 0;
    opacity: 1;
  }
`
