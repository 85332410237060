import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > .logotipo {
    display: grid;
    place-items: center;
    margin-bottom: 0;

    > a {
      > img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 70px;
      }
    }
  }

  > .row {
    width: 100%;
  }
`
