import styled from 'styled-components'

export const Header = styled.header`
  height: 90px;
  border-top: 3px solid var(--primary-color);
  position: relative;
  z-index: 9;

  > .row {
    align-content: stretch;

    > div {
      display: grid;
      place-items: center;

      > .logotipo {
        > a {
          > img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 60px;
          }
        }
      }

      &.sidebar {
        max-width: 280px;
        background-color: #fff;
      }

      &.topbar {
        width: 100%;
        height: 90px;

        > .user {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 0.9rem;

          > .username {
            font-weight: 600;
            color: #fff;
          }

          > .links {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;

            > li {
              list-style: none;

              > a,
              > button {
                color: #fff;

                &:hover {
                  text-decoration: underline;
                }
              }

              &:not(:last-child)::after {
                content: '|';
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
  }
`

export const VideoWrapper = styled.div`
  width: 100%;
  height: 140px;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;

  > iframe {
    width: calc(100% + 140px);
    aspect-ratio: 16/9;
    position: absolute;
    border: 0;
    left: 0;
    top: 0;
  }

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background: linear-gradient(180deg, #131313A1 30%, rgba(0, 0, 0, 0.5) 100%);
    position: relative;
    z-index: 1;
  }
`
