import React, { useContext, useRef, useState } from 'react'
import LoginContext from '@contexts/Login'
import { Spinner } from 'react-bootstrap'
import * as $Auth from '@services/Auth'
import AuthContext from '@contexts/Auth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const SecurityCode: React.FC<any> = () => {
  const { setUser, setToken, setIsAuthenticated } = useContext(AuthContext)
  const { email, setEmail, password, setPassword, isLoading, setIsLoading, error, setError } = useContext(LoginContext)

  const navigate = useNavigate()

  const [ securityCode, setSecurityCode ] = useState<string[]>(['', '', '', '', '', ''])
  const [ isLoadingResend, setIsLoadingResend ] = useState(false)
  const inputRefs = useRef<HTMLInputElement[]>([])

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...securityCode]
    values[index] = event.target.value
    setSecurityCode(values)

    if (event.target.value.length === 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      event.preventDefault()

      setSecurityCode(securityCode => {
        const values = [...securityCode]
        values[index] = ''
        return values
      })

      inputRefs.current[index - 1].focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault()
    const paste = event.clipboardData.getData('text')

    let index = 0

    for (let i = 0; i < 6; i++) {
      inputRefs.current[i].value = paste[index]

      index++
      if (index === 6)
        break
    }

    setSecurityCode(inputRefs.current.map(input => input.value))
  }

  const resend = () => {
    setIsLoadingResend(true)

    $Auth.securityCode(email, password).catch(e => {
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar realizar o login. Favor tentar novamente.')
    }).finally(() => setIsLoadingResend(false))
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.login(email, password, securityCode.join('')).then(({ data: { user, token } }) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setUser(user)
      setToken(token)
      setIsAuthenticated(true)
      navigate('/')
    }).catch(e => {
      setError(e.response?.data?.message || 'Ocorreu um erro inesperado. Tente novamente mais tarde.')
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="row">
      <div className="col-12 col-md-6 offset-md-3">
        <div className="card">
          <div className="card-header">
            <h1 className="h6 w-100 d-block text-center mb-0">
              Um código PIN foi enviado ao seu e-mail de cadastro. Insira o código nos campos abaixo para garantir seu acesso.
            </h1>
          </div>

          <form onSubmit={onSubmit}>
            <div className="card-body">
              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}

              <div className="row">
                {securityCode.map((code, index) => (
                  <div className="col-2" key={index}>
                    <input
                      ref={input => {
                        if (input) inputRefs.current[index] = input
                      }}
                      type="text"
                      className="form-control text-center"
                      value={code}
                      onChange={event => handleChange(index, event)}
                      onKeyDown={event => handleKeyDown(index, event)}
                      onPaste={handlePaste}
                      maxLength={1}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="card-footer">
              <div className="d-flex justify-content-between gap-10">
                <button type="button" className="btn btn-secondary d-block w-100" disabled={isLoading || isLoadingResend} onClick={resend}>
                  <span>Reenviar PIN</span> {isLoadingResend && <Spinner animation="border" className="ml-2" size="sm" />}
                </button>

                <button type="submit" className="btn btn-primary d-block w-100" disabled={isLoading || isLoadingResend}>
                  <span>Validar</span> {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SecurityCode
