import styled from 'styled-components'

export const Nav = styled.ul`
  > .nav-header {
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    font-size: 0.735rem;
    padding: 0 1.2rem;
  }

  > .nav-item {
    padding: 0 0.2rem;
    margin-bottom: 0.1rem;

    > .nav-link {
      display: block;
      transition: all 0.3s ease;
      color: var(--primary-color);
      padding: 0.65rem 1rem;
      text-decoration: none;
      position: relative;

      > * {
        position: relative;
        z-index: 1;
      }

      &::before {
        width: 0%;
        height: 100%;
        content: '';
        transition: all 0.25s ease;
        background: linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
        border-radius: 6px;
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
      }
    }

    &:hover > .nav-link,
    &.active > .nav-link {
      color: #fff;

      &::before {
        width: 100%;
        opacity: 1;
      }
    }
  }
`
