import axios, { AxiosResponse } from 'axios'
import { MutationFunction } from 'react-query'
import IDocumentCategory from '@interfaces/IDocumentCategory'

export const create: MutationFunction<AxiosResponse<any, any>, IDocumentCategory> = (category) => {
  return axios.post('v1/admin/document-categories', category)
}

export const update: MutationFunction<AxiosResponse<any, any>, [number, IDocumentCategory]> = ([id, category]) => {
  return axios.post(`v1/admin/document-categories/${id}`, category)
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.delete(`v1/admin/document-categories/${id}`)
}
