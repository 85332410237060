import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $Opportunity from '@services/Opportunity'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import useOpportunityColumns from '@hooks/useOpportunityColumns'
import IOpportunity from '@interfaces/IOpportunity'
import Action from './Action/Action'
import Opportunity from './Opportunity/Opportunity'

const Opportunities: React.FC<any> = () => {
  const [ opportunity, setOpportunity ] = useState<IOpportunity|null>(null)
  const [ showRegister, setShowRegister ] = useState(false)

  const SweetAlert = withReactContent(Swal)

  const { isLoading, data: response, refetch } = useQuery('opportunities', $Opportunity.all)

  const reject = useMutation($Opportunity.reject)

  const onReject = (opportunity: IOpportunity) => {
    SweetAlert.fire({
      title: 'Rejeitar Oportunidade',
      text: 'Tem certeza que deseja rejeitar esta oportunidade?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          text: 'Rejeitando oportunidade...',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        reject.mutate(opportunity.id, {
          onSuccess: () => {
            SweetAlert.fire({
              title: 'Sucesso!',
              text: 'Oportunidade rejeitada com sucesso!',
              icon: 'success',
            })

            refetch()
          },
          onError: () => {
            SweetAlert.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro ao rejeitar a oportunidade!',
              icon: 'error',
            })
          }
        })
      }
    })
  }

  const columns = useOpportunityColumns({
    onApprove: (opportunity: IOpportunity) => setOpportunity(opportunity),
    onReject,
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: response?.data || [],
  } as any, useSortBy, usePagination)

  const onClose = () => {
    setOpportunity(null)
    setShowRegister(false)
    refetch()
  }

  return (
    <>
      <Action opportunity={opportunity} onClose={onClose} />

      <Opportunity show={showRegister} onClose={onClose} />

      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Gestão de Oportunidades</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Gestão de Oportunidades</li>
            </ol>
          </nav>
        </div>

        <button className="btn btn-outline-primary" onClick={() => setShowRegister(true)}>Registrar Oportunidade</button>
      </div>

      <div className="card mb-3">
        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
              emptyMessage="Não há oportunidades registradas."
            />
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            onClick={gotoPage}
          />
        </div>
      </div>
    </>
  )
}

export default Opportunities
