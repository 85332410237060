import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IOpportunity from '@interfaces/IOpportunity'

export const all: QueryFunction<AxiosResponse<IOpportunity[]>> = () => {
  return axios.get('v1/admin/opportunities')
}

export const approve: MutationFunction<AxiosResponse<any, any>, [number, FormData]> = ([id, formData]) => {
  formData.append('_method', 'PUT')
  return axios.post(`v1/admin/opportunities/${id}/approve`, formData)
}

export const reject: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.put(`v1/admin/opportunities/${id}/reject`)
}

export const create: MutationFunction<AxiosResponse<any, any>, FormData> = (opportunity) => {
  return axios.post('v1/admin/opportunities', opportunity)
}
