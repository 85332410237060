import React, { useMemo } from 'react'
import Mask from '@helpers/Mask'
import { AiOutlineEdit } from 'react-icons/ai'
import { BsTrash } from 'react-icons/bs'
import { Link } from 'react-router-dom'

type Props = {
  onApprove: (id: number) => void
  onReject: (id: number) => void
  onDelete: (id: number) => void
}

const useClientColumns = ({ onApprove, onReject, onDelete }: Props) => {
  return useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'edit',
        disableSortBy: true,
        Cell: ({ row: { original: client } }: any) => (
          <Link to={`/clientes/${client.id}`} className="btn btn-link p-0">
            <AiOutlineEdit size={20} />
          </Link>
        ),
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ row: { original: client } }: any) => (
          <Link to={`/clientes/${client.id}`}>
            {client.id}
          </Link>
        ),
      },
      {
        Header: 'Logotipo',
        Cell: ({ row: { original: client } }: any) => {
          return (
            <figure className="logotipo mb-0">
              <Link to={`/clientes/${client.id}`}>
                {client.logotipo?.thumbnail_url?.length > 0 && (
                  <img src={client.logotipo?.thumbnail_url} alt={client.trading_name} />
                )}
              </Link>
            </figure>
          )
        }
      },
      {
        Header: 'Razão Social',
        accessor: 'company_name',
      },
      {
        Header: 'Nome Fantasia',
        accessor: 'trading_name',
      },
      {
        Header: 'CNPJ',
        accessor: 'cnpj',
        Cell: ({ row: { original: client } }: any) => client?.cnpj ? Mask.cnpj(client.cnpj) : <span className="text-muted">N/A</span>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original: client } }: any) => {
          if (client.status === 'approved') {
            return <span className="badge bg-success">Aprovado</span>
          } else if (client.status === 'rejected') {
            return <span className="badge bg-danger">Recusado</span>
          } else {
            return <span className="badge bg-warning">Aguardando aprovação</span>
          }
        },
      },
      {
        Header: 'Ações',
        disableSortBy: true,
        Cell: ({ row: { original: client } }: any) => {
          return (
            <>
              {client.status === 'pending' && (
                <div className="btn-group me-2">
                  <button
                    className="btn btn-primary btn-sm pe-2 ps-2"
                    onClick={() => onApprove(client.id)}
                  >Aprovar</button>

                  <button
                    className="btn btn-outline-danger btn-sm pe-2 ps-2"
                    onClick={() => onReject(client.id)}
                  >Recusar</button>
                </div>
              )}

              <button className="btn btn-danger btn-sm pe-2 ps-2" onClick={() => onDelete(client.id)}>
                <BsTrash size={20} />
              </button>
            </>
          )
        },
        style: {
          textAlign: 'right',
        },
      },
    ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useClientColumns
