import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IUser from '@interfaces/IUser'

export const all: QueryFunction<AxiosResponse<IUser[]>> = () => {
  return axios.get('v1/admin/admins')
}

export const find: QueryFunction<AxiosResponse<any, any>, [number]> = ({ queryKey: [ id ] }) => {
  return axios.get(`v1/admin/admins/${id}`)
}

export const create: MutationFunction<AxiosResponse<any, any>, IUser> = admin => {
  return axios.post('v1/admin/admins', admin)
}

export const update: MutationFunction<AxiosResponse<any, any>, IUser> = admin => {
  return axios.put(`v1/admin/admins/${admin.id}`, admin)
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.delete(`v1/admin/admins/${id}`)
}
