import React, { useContext, useState, useRef } from 'react'
import ClientContext from '@contexts/Client'
import { BsMegaphone, BsSearch } from 'react-icons/bs'
import INote from '@interfaces/INote'
import { Modal } from 'react-bootstrap'
import { random } from '@helpers/Utils'
import { useMutation } from 'react-query'
import { create, update } from '@services/Note'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import moment from 'moment'
import { Editor } from '@tinymce/tinymce-react'
import { IoMdClose } from 'react-icons/io'
import IDocumentCategory from '@interfaces/IDocumentCategory'
import IDocument from '@interfaces/IDocument'
import { AiOutlineCloudDownload } from 'react-icons/ai'

const translation: any = {
  types: {
    info: 'Informação',
    reminder: 'Lembrete',
    task: 'Tarefa',
    warning: 'Aviso',
    alert: 'Alerta',
    important: 'Importante',
  },
  status: {
    active: 'Ativo',
    read: 'Lido',
    inactive: 'Inativo',
  },
}

const Notes: React.FC<any> = () => {
  const { client, note, setNote, refetch } = useContext(ClientContext)

  const [ documentCategory, setDocumentCategory ] = useState<IDocumentCategory|null>(null)

  const SweetAlert = withReactContent(Swal)
  const documentCategoryRef = useRef<HTMLSelectElement>(null)
  const documentRef = useRef<HTMLSelectElement>(null)

  const { mutate, isLoading } = useMutation((data: INote) => note?.id && note.id > 0 ? update(data) : create(data))

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLSelectElement|HTMLTextAreaElement|HTMLInputElement>) => setNote({
    ...note || {},
    [name]: value,
  } as INote)

  const handleSendEmailNotification = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => setNote({
    ...note,
    send_email_notification: checked,
  } as INote)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!note)
      return

    SweetAlert.fire({
      title: 'Registrando aviso...',
      text: 'Este processo pode demorar dependendo da quantidade de documentos anexados. Por favor, aguarde!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    mutate(note, {
      onSuccess: () => {
        refetch()
        SweetAlert.fire({
          title: 'Sucesso!',
          text: note.id > 0 ? 'Aviso atualizado com sucesso!' : 'Aviso registrado com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => setNote(null))
      },
      onError: (error: any) => {
        SweetAlert.fire({
          title: 'Erro!',
          text: error?.response?.data?.message || 'Ocorreu um erro ao ' + (note.id > 0 ? 'atualizar' : 'registrar') + ' o aviso!',
          icon: 'error',
          showConfirmButton: true,
        })
      },
    })
  }

  const handleDocumentCategoryChange = ({ target: { value: documentCategoryId } }: React.ChangeEvent<HTMLSelectElement>) => {
    const index = client?.document_categories?.findIndex((documentCategory: IDocumentCategory) => documentCategory.id === parseInt(documentCategoryId))

    if (index !== undefined && index > -1 && client?.document_categories[index]) {
      setDocumentCategory(client.document_categories[index])
    } else {
      setDocumentCategory(null)
    }
  }

  const handleDocumentChange = ({ target: { value: documentId } }: React.ChangeEvent<HTMLSelectElement>) => {
    const index = documentCategory?.documents?.findIndex((document: any) => document.id === parseInt(documentId))

    if (index !== undefined && index > -1 && documentCategory?.documents[index]) {
      setNote({
        ...note || {},
        documents: [
          ...note?.documents || [],
          documentCategory.documents[index],
        ],
      } as INote)
    }
  }

  const removeDocument = (document: IDocument) => {
    setNote({
      ...note || {},
      documents: note?.documents?.filter((d: IDocument) => d.id !== document.id) || [],
    } as INote)
  }

  const canEdit = (!note || note.id < 0)

  return (
    <>
      <Modal
        show={!!note}
        onHide={() => setNote(null)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{note?.id && note.id > 0 ? note.title : 'Adicionar aviso'}</Modal.Title>
        </Modal.Header>

        <form onSubmit={onSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label htmlFor="title">Título:</label>
                  <input type="text" name="title" id="title" className="form-control" defaultValue={note?.title} onChange={handleInputChange} disabled={!canEdit} required />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="type">Tipo:</label>
                  <select name="type" id="type" className="form-control" defaultValue={note?.type} onChange={handleInputChange} disabled={!canEdit} required>
                    <option></option>
                    <option value="info">Informação</option>
                    <option value="reminder">Lembrete</option>
                    <option value="task">Tarefa</option>
                    <option value="warning">Aviso</option>
                    <option value="alert">Alerta</option>
                    <option value="important">Importante</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="expires_at">Valido até:</label>
                  <input
                    type="datetime-local"
                    name="expires_at"
                    id="expires_at"
                    className="form-control"
                    defaultValue={note?.expires_at ? moment(note.expires_at).format('YYYY-MM-DDTHH:mm') : ''}
                    onChange={handleInputChange}
                    disabled={!canEdit}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className={'form-group' + (!note?.id || note.id < 0 ? ' mb-3' : '')}>
                  <label htmlFor="content">Conteúdo:</label>
                  <Editor
                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                    initialValue={note?.content || ''}
                    init={{
                      height: 220,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; }'
                    }}
                    onChange={(e) => setNote({
                      ...note || {},
                      content: e.target.getContent(),
                    } as INote)}
                    disabled={!canEdit}
                  />
                </div>
              </div>

              {canEdit && (
                <div className="col-12 col-md-6">
                  <div className="form-check">
                    <input
                      id="owner"
                      type="checkbox"
                      className="form-check-input"
                      defaultChecked={note?.send_email_notification}
                      onChange={handleSendEmailNotification}
                    />
                    <label className="form-check-label" htmlFor="owner">Notificar por e-mail?</label>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>

          {canEdit && (
            <Modal.Body className="pb-0 border-top">
              <h6>Anexar documentos:</h6>

              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="input-group mb-3">
                    <label htmlFor="document_category_id" className="input-group-text">Categoria</label>
                    <select
                      ref={documentCategoryRef}
                      name="document_category_id"
                      id="document_category_id"
                      className="form-select"
                      onChange={handleDocumentCategoryChange}
                    >
                      <option>- Selecione -</option>
                      {client?.document_categories?.map(documentCategory => (
                        <option key={documentCategory.id} value={documentCategory.id}>{documentCategory.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-8">
                  <div className="input-group mb-3">
                    <label htmlFor="document_id" className="input-group-text">Documento</label>
                    <select
                      ref={documentRef}
                      name="document_id"
                      id="document_id"
                      className="form-select"
                      onChange={handleDocumentChange}
                      disabled={!documentCategory}
                    >
                      <option>- Selecione -</option>
                      {documentCategory?.documents.map(document => (
                        <option key={document.id} value={document.id}>{document.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </Modal.Body>
          )}

          {note?.documents && note.documents.length > 0 && (
            <Modal.Body className="pb-0 border-top">
              <h6>Documentos anexados:</h6>
              <table className="table table-striped table-sm">
                <tbody>
                  {note.documents.map(document => (
                    <tr key={document.id}>
                      <td>
                        {document.id > 0 ? (
                          <a href={document.file as string} className="pt-1 pb-1" target="_blank" rel="noreferrer" download>{document.name}</a>
                        ) : (
                          <span>{document.name}</span>
                        )}
                      </td>

                      <td className="text-end">
                        {canEdit ? (
                          <button type="button" className="btn btn-link btn-sm" onClick={() => removeDocument(document)}>
                            <IoMdClose size={20} />
                          </button>
                        ) : (
                          <a href={document.file as string} className="btn btn-link btn-sm" target="_blank" rel="noreferrer" download>
                            <AiOutlineCloudDownload size={20} />
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
          )}

          {canEdit && (
            <Modal.Footer>
              <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>Enviar</button>
            </Modal.Footer>
          )}
        </form>
      </Modal>

      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-end mb-2">
            <button
              className="btn btn-link btn-sm d-flex align-items-center p-0"
              onClick={() => setNote({
                id: -random(),
                send_email_notification: true,
                client_id: client?.id,
              } as INote)}
            >
              Adicionar aviso <BsMegaphone className="ms-1" size={20} />
            </button>
          </div>

          <div className="table-responsive">
            <table className="table table-secondary table-striped table-md mb-0">
              <thead>
                <tr>
                  <th style={{ width: 60 }}></th>
                  <th>Título</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th>Válido até</th>
                  <th style={{ width: 160, textAlign: 'right' }}>Registrado em</th>
                </tr>
              </thead>

              <tbody>
                {client?.notes ? client.notes.map((note: INote) => (
                  <tr key={note.id}>
                    <td>
                      <button className="btn btn-link p-0" onClick={() => setNote(note)}>
                        <BsSearch size={18} />
                      </button>
                    </td>
                    <td>{note.title}</td>
                    <td>
                      <span className={'badge bg-' + note.type}>
                        {translation.types[note.type]}
                      </span>
                    </td>
                    <td>{translation.status[note.status]}</td>
                    <td>{note.expires_at ? moment(note.expires_at).format('DD/MM/YYYY [ás] HH:mm') : <span className="text-muted">N/A</span>}</td>
                    <td style={{ textAlign: 'right' }}>{moment(note.created_at).format('DD/MM/YYYY')}</td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={5} className="text-center">Nenhum aviso registrado.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notes
