import React, { useRef } from 'react'
import Mask from '@helpers/Mask'
import PropTypes from 'prop-types'

const Cnpj: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = (props) => {
  const ref = useRef<HTMLInputElement|null>(null)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const document = event.target.value.slice(0, 18)

    if (ref.current) {
      ref.current.value = Mask.cnpj(document)
    }

    props.onChange?.({
      ...event,
      target: {
        ...event.target,
        name: props.name || event.target.name,
        value: document.replace(/\D+/g, ''),
      },
    })
  }

  return (
    <input
      {...props}
      ref={ref}
      onChange={onChange}
    />
  )
}

Cnpj.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default Cnpj
