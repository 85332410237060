import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Container } from './Auth.styles'

const Auth: React.FC<any> = () => {
  return (
    <Container className="container">
      <figure className="logotipo mb-5">
        <Link to="/">
          <img src={require('./../../resources/imgs/logotipo.webp')} alt="Loqed Systems®" className="img-fluid" />
        </Link>
      </figure>

      <Outlet />
    </Container>
  )
}

export default Auth
