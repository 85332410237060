import React, { useState, useMemo, useEffect } from 'react'
import * as Bootstrap from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

type Props = {
  page: number
  pageCount: number
  canPreviousPage: boolean
  canNextPage: boolean
  onClick: (page: number) => void
}

const Pagination: React.FC<Props> = ({ page, pageCount, canPreviousPage, canNextPage, onClick }: Props) => {
  const [currentPage, setCurrentPage] = useState(page)

  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const page = parseInt(queryParams.get('page') || '1', 10)

    setCurrentPage(page - 1)
  }, [location])

  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    onClick(pageNumber)
  }

  const pageNumbers = useMemo((): Array<number> => {
    const numbers = []

    let start = currentPage - 2, end = currentPage + 2

    if (end > pageCount) {
      start -= (end - pageCount)
      end = pageCount
    }

    if (start <= 0) {
      end += ((start - 1) * (-1))
      start = 0
    }

    end = end > pageCount ? pageCount : end

    for (let i = start; i < end; i++) numbers.push(i)

    return numbers
  }, [currentPage, pageCount])

  return (
    <Bootstrap.Pagination className="mb-0">
      <Bootstrap.Pagination.First disabled={!canPreviousPage} onClick={() => handleClick(0)} />
      <Bootstrap.Pagination.Prev disabled={!canPreviousPage} onClick={() => handleClick(currentPage - 1)} />

      {pageNumbers.map((pageNumber: number) => (
        <Bootstrap.Pagination.Item key={pageNumber} active={pageNumber === currentPage} onClick={() => handleClick(pageNumber)}>
          {pageNumber + 1}
        </Bootstrap.Pagination.Item>
      ))}

      <Bootstrap.Pagination.Next disabled={!canNextPage} onClick={() => handleClick(currentPage + 1)} />
      <Bootstrap.Pagination.Last disabled={!canNextPage} onClick={() => handleClick(pageCount - 1)} />
    </Bootstrap.Pagination>
  )
}

export default Pagination