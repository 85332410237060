import React, { useMemo, useContext } from 'react'
import AuthContext from '@contexts/Auth'
import IUser from '@interfaces/IUser'
import { AiOutlineEdit } from 'react-icons/ai'
import { BsTrash } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const useAdminColumns = (onDelete: (admin: IUser) => void) => {
  const { user } = useContext(AuthContext)

  return useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'edit',
        disableSortBy: true,
        Cell: ({ row: { original: admin } }: any) => (
          <Link to={`/administradores/${admin.id}`} className="btn btn-link p-0">
            <AiOutlineEdit size={20} />
          </Link>
        ),
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ row: { original: admin } }: any) => (
          <Link to={`/administradores/${admin.id}`}>
            {admin.id}
          </Link>
        ),
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Ações',
        disableSortBy: true,
        Cell: ({ row: { original: admin } }: any) => (
          <button className={'btn btn-sm pe-2 ps-2 btn-' + (admin.id === user?.id ? 'outline-secondary' : 'danger')} onClick={() => onDelete(admin)} disabled={admin.id === user?.id}>
            <BsTrash size={20} />
          </button>
        ),
        style: {
          width: 100,
          textAlign: 'right',
        },
      },
    ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useAdminColumns
