import styled from 'styled-components'

export const Categories = styled.div`
  height: 100%;
  box-shadow: none;
  border: 1px solid #f0f0f0;

  > .card-body {
    > .btn {
      width: 100%;
    }

    > .nav {
      > .nav-item {
        > .nav-link {
          color: var(--primary-color);

          &.active {
            background-color: var(--primary-color);
            color: #fff;
          }
        }
      }
    }
  }
`

export const Panel = styled.div`
  > .tab-content {
    height: 100%;

    > .tab-pane {
      height: 100%;

      > .card {
        height: 100%;
        box-shadow: none;
        border: transparent;

        > .card-header {
          border: none;
          background: #222;
          color: #fff;
        }

        > .card-body {
          min-height: 400px;
          border: 1px solid #f0f0f0;
          border-radius: 0 0 6px 6px;
          position: relative;
          border-top: 0;

          > .alert {
            opacity: 0.6;
            transition: all 0.3s ease;
          }

          > .table-responsive {
            position: relative;
            max-height: calc(100vh - 270px - 4rem);

            > .table > tbody > tr > td {
              border-bottom: 1px solid #f0f0f0;
            }
          }

          > .upload-overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
            background-color: #c4ccd145;
            transition: all 0.3s ease;
            position: absolute;
            border-radius: 6px;
            visibility: hidden;
            opacity: 0;
            left: 1rem;
            top: 1rem;

            > .content {
              height: 140px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fff url(${require('@resources/imgs/clouds.png')}) top center no-repeat;
              box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
              border-radius: 6px;
              padding: 2rem 3rem;
              position: relative;
              margin-top: 40px;

              &::before {
                content: '';
                display: block;
                width: 100%;
                height: 118px;
                background: url(${require('@resources/imgs/files.png')}) top center no-repeat;
                position: absolute;
                top: -76px;
                left: 0;
              }

              > span {
                font-weight: 300;
                font-size: 1.4rem;

                > strong {
                  font-weight: 600;
                }
              }
            }
          }

          &.dragging {
            > .upload-overlay {
              visibility: visible;
              opacity: 1;
            }

            > .alert {
              opacity: 0;
            }
          }
        }
      }
    }
  }
`

export const Files = styled.div``
