import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Nav } from './Sidebar.styles'
import PanelContext from '@contexts/Panel'

const Sidebar: React.FC<any> = () => {
  const { activeMenu } = useContext(PanelContext)

  return (
    <nav className="main-menu">
      <Nav className="nav flex-column">
        <li className="nav-header">Gerenciamento</li>

        <li className={'nav-item' + (['/', '/clientes'].includes(activeMenu) ? ' active' : '')}>
          <Link to="/clientes" className="nav-link">
            <span>Clientes</span>
          </Link>
        </li>

        <li className={'nav-item mb-3' + (activeMenu === '/oportunidades' ? ' active' : '')}>
          <Link to="/oportunidades" className="nav-link">
            <span>Gestão de Oportunidades</span>
          </Link>
        </li>

        <li className="nav-header">Administração</li>

        <li className={'nav-item' + (activeMenu === '/administradores' ? ' active' : '')}>
          <Link to="/administradores" className="nav-link">
            <span>Administradores</span>
          </Link>
        </li>
      </Nav>
    </nav>
  )
}

export default Sidebar
