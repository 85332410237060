import React, { createContext, useState, useEffect } from 'react'
import UploadList from '@components/UploadList/UploadList'
import { create } from '@services/Document'
import { UseMutateFunction, useMutation } from 'react-query'
import { Outlet } from 'react-router-dom'
import { AxiosResponse } from 'axios'

type DocumentContextProps = {
  mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>
  tempList: any[]
  setTempList: React.Dispatch<React.SetStateAction<any[]>>
  successList: number[]
  setSuccessList: React.Dispatch<React.SetStateAction<number[]>>
  errorList: number[]
  setErrorList: React.Dispatch<React.SetStateAction<number[]>>
}

const DocumentContext = createContext<DocumentContextProps>({ } as DocumentContextProps)

export const DocumentProvider: React.FC<any> = () => {
  const [ tempList, setTempList ] = useState<any[]>([])
  const [ successList, setSuccessList ] = useState<number[]>([])
  const [ errorList, setErrorList ] = useState<number[]>([])

  const { mutate } = useMutation((item: any) => {
    const formData = new FormData()

    formData.append('file', item.file)
    formData.append('document_category_id', item.document_category_id)
    formData.append('client_id', item.client_id)

    return create(formData)
  }, {
    retry: false,
    onError: (err, { id }) => {
      setErrorList(errorList => [
        ...errorList,
        id,
      ])
    },
    onSuccess: (data, { id }) => {
      setSuccessList(successList => [
        ...successList,
        id,
      ])
    },
  })

  useEffect(() => {
    const processList = async (list: any[]) => {
      if (list.length === 0) {
        return
      }

      const [item, ...rest] = list
      await mutate(item)
      await processList(rest)
    }

    if (tempList.length > 0) {
      processList(tempList)
    }
  }, [mutate, tempList])

  return (
    <DocumentContext.Provider
      value={{
        mutate,
        tempList,
        setTempList,
        successList,
        setSuccessList,
        errorList,
        setErrorList,
      }}
    >
      <UploadList />
      <Outlet />
    </DocumentContext.Provider>
  )
}

export default DocumentContext
