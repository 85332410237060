import axios, { AxiosResponse } from 'axios'
import { MutationFunction } from 'react-query'

export const create: MutationFunction<AxiosResponse<any, any>, FormData> = formData => {
  return axios.post('v1/admin/documents', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = id => {
  return axios.delete(`v1/admin/documents/${id}`)
}
