interface ObjectToFormDataOptions {
  exclude?: string[]
  transform?: (key: string, value: any) => { key: string, value: any }
  filter?: { key: string, callback: (key: string, value: any) => boolean }
  prefix?: string
  dots?: boolean
}

const convertStringToWildcard = (input: string) => input.replace(/\[([^\]]+)\]/g, (match, capture) => capture === '*' ? match : '.' + capture)

const testKey = (key: string, fullKey: string) => new RegExp(key.replace(/\*/g, '[^.]*') + '(.*)').test(convertStringToWildcard(fullKey))

const objectToFormData = (obj: any, options: ObjectToFormDataOptions = {}): FormData => {
  const formData = new FormData()
  const { exclude = [], transform, filter, prefix = '', dots } = options

  const addToFormData = (obj: any, parentKey?: string) => {
    if (obj === null || obj === undefined || typeof obj !== 'object')
      return

    Object.keys(obj).forEach(key => {
      let fullKey = parentKey ? `${parentKey}.${key}` : key
      let value = obj[key]

      if (parentKey)
        fullKey = `${parentKey}[${key}]`

      const excludeKey = exclude.some(excludedKey => testKey(excludedKey, fullKey))

      if (excludeKey)
        return

      if (dots)
        fullKey = fullKey.replace(/\[([^\]]+)\]/g, '.$1')

      if (typeof transform === 'function')
        ({ key, value } = transform(key, value))

      if (typeof filter !== 'undefined' && testKey(filter.key, fullKey) && !filter.callback(key, value))
        return

      if (value instanceof Date) {
        formData.append(fullKey, value.toISOString())
      } else if (value instanceof File) {
        formData.append(fullKey, value, value.name)
      } else if (typeof obj[key] === 'object') {
        addToFormData(obj[key], fullKey)
      } else {
        formData.append(prefix + fullKey, value)
      }
    })
  }

  addToFormData(obj)

  return formData
}

export default objectToFormData
