import React, { useRef } from 'react'
import Mask from '@helpers/Mask'
import PropTypes from 'prop-types'

const Phone: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = (props) => {
  const ref = useRef<HTMLInputElement|null>(null)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value.slice(0, 16)

    if (ref.current) {
      ref.current.value = Mask.phone(phone, true)
    }

    props.onChange?.({
      ...event,
      target: {
        ...event.target,
        name: props.name || event.target.name,
        value: phone.replace(/\D+/g, ''),
      },
    })
  }

  return (
    <input
      {...props}
      ref={ref}
      onChange={onChange}
    />
  )
}

Phone.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default Phone
