import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Header as HeaderComponent, VideoWrapper } from './Header.styles'
import AuthContext from '@contexts/Auth'

const Header: React.FC<any> = () => {
  const { user, logout } = useContext(AuthContext)

  const autoplay = process.env.REACT_APP_VIDEO_AUTOPLAY === 'true' ? 1 : 0

  return (
    <>
      <HeaderComponent className="container-fluid">
        <div className="row">
          <div className="col sidebar">
            <figure className="logotipo mb-0">
              <Link to="/">
                <img src={require('./../../resources/imgs/logotipo.webp')} alt="Loqed Systems®" />
              </Link>
            </figure>
          </div>

          <div className="col topbar d-flex justify-content-end align-items-center">
            <div className="user">
              <div className="username">{user?.name}</div>
              <ul className="links">
                <li>
                  <Link to={`/administradores/${user?.id}`}>Minha Conta</Link>
                </li>

                <li>
                  <button className="btn btn-link p-0" onClick={logout}>Sair</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </HeaderComponent>

      <VideoWrapper>
        <iframe
          allowFullScreen={true}
          allow={'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' + (autoplay === 1 ? '; autoplay' : '')}
          src={`https://www.youtube.com/embed/hUMhJ0m5fEs?
            controls=0&amp;
            rel=0&amp;
            playsinline=1&amp;
            enablejsapi=1&amp;
            origin=https%3A%2F%2Floqedsystems.com.br&amp;
            autoplay=${autoplay}&amp;
            mute=1&amp;
            loop=1`}
        ></iframe>
      </VideoWrapper>
    </>
  )
}

export default Header
