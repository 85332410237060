import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IClient from '@interfaces/IClient'

export const all: QueryFunction<AxiosResponse<IClient[]>> = () => {
  return axios.get('v1/admin/clients')
}

export const find: QueryFunction<AxiosResponse<any, any>, [number]> = ({ queryKey: [ id ] }) => {
  return axios.get(`v1/admin/clients/${id}`)
}

export const create: MutationFunction<AxiosResponse<any, any>, FormData> = (formData) => {
  return axios.post('v1/admin/clients', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const update: MutationFunction<AxiosResponse<any, any>, [number, FormData]> = ([id, formData]) => {
  formData.append('_method', 'PUT')

  return axios.post(`v1/admin/clients/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const approve: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.post(`v1/admin/clients/${id}/approve`)
}

export const reject: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.post(`v1/admin/clients/${id}/reject`)
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.delete(`v1/admin/clients/${id}`)
}
