import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import INote from '@interfaces/INote'

export const all: QueryFunction<AxiosResponse<INote[]>> = () => {
  return axios.get('v1/admin/notes')
}

export const find: QueryFunction<AxiosResponse<any, any>, [number]> = ({ queryKey: [ id ] }) => {
  return axios.get(`v1/admin/notes/${id}`)
}

export const create: MutationFunction<AxiosResponse<any, any>, INote> = (note) => {
  return axios.post('v1/admin/notes', note)
}

export const update: MutationFunction<AxiosResponse<any, any>, INote> = (note) => {
  return axios.put(`v1/admin/notes/${note.id}`, note)
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.delete(`v1/admin/notes/${id}`)
}
