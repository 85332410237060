import React, { useContext } from 'react'
import DocumentContext from '@contexts/Document'
import { Card } from './UploadList.styles'
import { Spinner } from 'react-bootstrap'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'

const UploadList: React.FC<any> = () => {
  const { mutate, tempList, setTempList, successList, setSuccessList, errorList, setErrorList } = useContext(DocumentContext)

  const retry = (item: any) => {
    setSuccessList(successList =>
      successList.filter(id => id !== item.id))

    setErrorList(errorList =>
      errorList.filter(id => id !== item.id))

    mutate(item)
  }

  const abort = () => {
    setTempList([])
    setSuccessList([])
    setErrorList([])
  }

  return (
    <Card className={'card' + (tempList.length > 0 ? ' show' : '')}>
      <div className="card-header">
        <h6 className="card-title mb-0">
          Fazendo upload de {tempList.length} arquivo{tempList.length === 1 ? '' : 's'}
        </h6>

        <button className="btn btn-abort p-0" onClick={abort}>
          <AiOutlineCloseCircle size={26} />
        </button>
      </div>

      <div className="card-body">
        <ul>
          {tempList.map(item => {
            const hasError = errorList.findIndex(id => id === item.id) !== -1
            const hasSuccess = successList.findIndex(id => id === item.id) !== -1

            return (
              <li key={item.id}>
                <span>{item.file.name}</span>

                {hasSuccess ? (
                  <AiOutlineCheckCircle size={22} color="success" />
                ) : hasError ? (
                  <button className="btn btn-link btn-sm p-0" onClick={() => retry(item)}>
                    <small>tentar novamente</small>
                  </button>
                ) : (
                  <Spinner animation="border" size="sm" variant="primary" />
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </Card>
  )
}

export default UploadList
