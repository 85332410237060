import styled from 'styled-components'

export const Logotipo = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  > input {
    position: absolute;
    visibility: hidden;
  }

  > .info {
    display: inline-block;
    background-color: #f0f0f0;
    transition: all 0.2s ease;
    border-radius: 6px;
    text-align: center;
    padding: 8px 20px;
  }

  &.has-image > .info {
    opacity: 0;
  }

  &:hover > .info {
    opacity: 1;
    background-color: var(--primary-color);
    color: #fff;
  }
`
