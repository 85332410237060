import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import App from '@layouts/App/App'
import AuthContext, { AuthProvider } from '@contexts/Auth'
import Auth from '@layouts/Auth/Auth'
import Login from '@screens/Auth/Login/Login'
import ForgotPassword from '@screens/Auth/ForgotPassword/ForgotPassword'
import ResetPassword from '@screens/Auth/ResetPassword/ResetPassword'
import { PanelProvider } from '@contexts/Panel'
import { LoginProvider } from '@contexts/Login'
import SecurityCode from '@screens/Auth/SecurityCode/SecurityCode'
import Clients from '@screens/Clients/Clients'
import { ClientProvider } from '@contexts/Client'
import Client from '@screens/Clients/Client/Client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { DocumentProvider } from '@contexts/Document'
import Admins from '@screens/Admins/Admins'
import Admin from '@screens/Admins/Admin/Admin'
import Opportunities from '@screens/Opportunities/Opportunities'

const RequireAuth: React.FC<any> = () => {
  const { isLoading, isAuthenticated } = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate('/login')
    }
  }, [isAuthenticated, isLoading, navigate])

  if (!isAuthenticated) return null

  return <Outlet />
}

const Router: React.FC<any> = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthProvider />}>
            <Route element={<RequireAuth />}>
              <Route element={<PanelProvider />}>
                <Route element={<App />}>
                  <Route element={<DocumentProvider />}>
                    <Route index element={<Clients />} />

                    <Route path="clientes" element={<Clients />} />
                    <Route element={<ClientProvider />}>
                      <Route path="clientes/registrar" element={<Client />} />
                      <Route path="clientes/:id" element={<Client />} />
                    </Route>

                    <Route path="oportunidades" element={<Opportunities />} />
                  </Route>

                  <Route path="administradores" element={<Admins />} />
                  <Route path="administradores/registrar" element={<Admin />} />
                  <Route path="administradores/:id" element={<Admin />} />
                </Route>
              </Route>
            </Route>

            <Route element={<Auth />}>
              <Route element={<LoginProvider />}>
                <Route path="login" element={<Login />} />
                <Route path="security-code" element={<SecurityCode />} />
              </Route>
              <Route path="recuperar-senha" element={<ForgotPassword />} />
              <Route path="redefinir-senha" element={<ResetPassword />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default Router
