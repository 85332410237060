import React from 'react'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import useAdminColumns from '@hooks/useAdminColumns'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { all, destroy as $destroy } from '@services/Admin'
import IUser from '@interfaces/IUser'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Admins: React.FC<any> = () => {
  const { isLoading, data: response, refetch } = useQuery('admins', all)
  const { mutate: destroy } = useMutation($destroy)

  const SweetAlert = withReactContent(Swal)

  const onDelete = (admin: IUser) => {
    SweetAlert.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter essa ação!',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          text: 'Deletando administrador...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        destroy(admin.id, {
          onSuccess: () => {
            refetch()
            SweetAlert.fire({
              title: 'Sucesso!',
              text: 'Administrador deletado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          },
          onError: (error: any) => {
            SweetAlert.fire({
              title: 'Erro!',
              text: error?.response?.data?.message || 'Ocorreu um erro ao deletar o administrador!',
              icon: 'error',
              showConfirmButton: true,
            })
          },
        })
      }
    })
  }

  const columns = useAdminColumns(onDelete)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: response?.data || [],
  } as any, useSortBy, usePagination)

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Administradores</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Administradores</li>
            </ol>
          </nav>
        </div>

        <Link to="/administradores/registrar" className="btn btn-outline-primary pe-3 ps-3">Registrar administrador</Link>
      </div>

      <div className="card mb-3">
        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
            />
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            onClick={gotoPage}
          />
        </div>
      </div>
    </>
  )
}

export default Admins
