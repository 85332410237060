import React, { createContext, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

type PanelContextProps = {
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  activeMenu: string
}

const PanelContext = createContext<PanelContextProps>({ } as PanelContextProps)

export const PanelProvider: React.FC<any> = () => {
  const [ collapsed, setCollapsed ] = useState<boolean>(true)

  const location = useLocation()

  const activeMenu = useMemo(() => {
    const parts = location.pathname.split('/')
    const path = parts[1] || ''
    return `/${path}`
  }, [location.pathname])

  return (
    <PanelContext.Provider
      value={{
        collapsed,
        setCollapsed,
        activeMenu,
      }}
    >
      <Outlet />
    </PanelContext.Provider>
  )
}

export default PanelContext
