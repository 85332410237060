import React, { createContext, useState, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query'
import { find } from '@services/Client'
import IClient from '@interfaces/IClient'
import { AxiosResponse } from 'axios'
import INote from '@interfaces/INote'

type ClientContextProps = {
  id: number|null
  client: IClient|null
  note: INote|null
  setNote: React.Dispatch<React.SetStateAction<INote|null>>
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  setClient: React.Dispatch<React.SetStateAction<IClient|null>>
  isLoading: boolean
  refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>
}

const ClientContext = createContext<ClientContextProps>({ } as ClientContextProps)

export const ClientProvider: React.FC<any> = () => {
  const [ client, setClient ] = useState<IClient|null>(null)
  const [ note, setNote ] = useState<INote|null>(null)
  const [ activeTab, setActiveTab ] = useState<string>('main')

  const { id }: any = useParams()

  const { isLoading, data: response, error, refetch } = useQuery(id, find, {
    enabled: Boolean(id),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  useEffect(() => {
    if (!isLoading && !error && response) {
      setClient(response.data)
    }
  }, [isLoading, error, response])

  return (
    <ClientContext.Provider
      value={{
        id: Number(id),
        client,
        setClient,
        note,
        setNote,
        activeTab,
        setActiveTab,
        isLoading,
        refetch,
      }}
    >
      <Outlet />
    </ClientContext.Provider>
  )
}

export default ClientContext
