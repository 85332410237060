import React from 'react'
import IOpportunity from '@interfaces/IOpportunity'
import withReactContent from 'sweetalert2-react-content'
import * as $Opportunity from '@services/Opportunity'
import Swal from 'sweetalert2'
import { useMutation } from 'react-query'
import { Modal } from 'react-bootstrap'

type Props = {
  opportunity: IOpportunity|null
  onClose: () => void
}

const Action: React.FC<Props> = ({ opportunity, onClose }) => {
  const SweetAlert = withReactContent(Swal)

  const approve = useMutation($Opportunity.approve)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!opportunity)
      return

    const formData = new FormData(event.currentTarget)

    SweetAlert.fire({
      title: 'Aguarde...',
      text: (opportunity.can_renewal ? 'Renovando' : 'Aprovando') + ' oportunidade...',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    approve.mutate([opportunity.id, formData], {
      onSuccess: () => {
        onClose()
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Oportunidade ' + (opportunity.can_renewal ? 'renovada' : 'aprovada') + ' com sucesso!',
          icon: 'success',
        })
      },
      onError: () => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao ' + (opportunity.can_renewal ? 'renovar' : 'aprovar') + ' a oportunidade!',
          icon: 'error',
        })
      }
    })
  }

  return (
    <Modal show={!!opportunity} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {(opportunity?.can_renewal || opportunity?.status === 'awaiting_renewal') ? 'Renovar' : 'Aprovar'} Oportunidade #{opportunity?.id}
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body className="pb-0">
          {opportunity?.renewal_description && opportunity.renewal_description.length > 0 && (
            <div className="mb-3">
              <label>Descrição da renovação:</label>
              <div className="alert alert-info mb-0 p-2">
                {opportunity?.renewal_description}
              </div>
            </div>
          )}

          <div className="form-group mb-3">
            <label htmlFor="expires_at">Data de vencimento:</label>
            <input type="datetime-local" className="form-control" id="expires_at" name="expires_at" required />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="notify_renewal_days">Dias para notificar vencimento:</label>
            <input type="number" className="form-control" id="notify_renewal_days" name="notify_renewal_days" defaultValue={15} min={1} required />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary btn-sm pe-2 ps-2">{(opportunity?.can_renewal || opportunity?.status === 'awaiting_renewal') ? 'Renovar' : 'Aprovar'} Oportunidade</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Action
