import React, { useContext, useState, useEffect } from 'react'
import ClientContext from '@contexts/Client'
import { Nav, Tab } from 'react-bootstrap'
import { Categories, Files, Panel } from './Document.styles'
import IDocumentCategory from '@interfaces/IDocumentCategory'
import { useMutation } from 'react-query'
import * as $DocumentCategory from '@services/DocumentCategory'
import * as $Document from '@services/Document'
import File from './File/File'
import DocumentContext from '@contexts/Document'
import { random } from '@helpers/Utils'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Documents: React.FC<any> = () => {
  const { client, refetch } = useContext(ClientContext)
  const { tempList, setTempList, successList } = useContext(DocumentContext)

  const [ documentCategory, setDocumentCategory ] = useState<IDocumentCategory|null>(null)
  const [ activeKey, setActiveKey ] = useState<string>('')
  const [ isDragging, setIsDragging ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)
  const styles = getComputedStyle(document.documentElement)

  const mutations = {
    category: {
      create: useMutation($DocumentCategory.create),
      destroy: useMutation($DocumentCategory.destroy),
    },
    document: {
      destroy: useMutation($Document.destroy),
    }
  }

  useEffect(() => {
    if (client?.document_categories && client.document_categories.length > 0 && (!activeKey || !client.document_categories.some(c => String(c.id) === activeKey))) {
      setActiveKey(String(client.document_categories[0].id))
    }
  }, [activeKey, client])

  useEffect(() => {
    if (successList.length > 0) {
      refetch()
    }
  }, [refetch, successList])

  const handleCategoryChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setDocumentCategory({
    ...documentCategory,
    [name]: value,
  } as IDocumentCategory)

  const onCreate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (documentCategory) {
      mutations.category.create.mutate(documentCategory, {
        onSuccess: ({ data }) => {
          setDocumentCategory(null)
          refetch().then(() => setActiveKey(data.id))
        },
      })
    }
  }

  const onAbort = () => setDocumentCategory(null)

  const onCategoryDelete = (category: IDocumentCategory) => {
    SweetAlert.fire({
      title: 'Excluir pasta',
      text: 'Tem certeza que deseja excluir a pasta ' + category.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonColor: styles.getPropertyValue('--primary-color'),
      cancelButtonText: 'Cancelar',
      cancelButtonColor: styles.getPropertyValue('--bs-secondary'),
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        SweetAlert.fire({
          title: 'Excluindo...',
          text: 'Aguarde enquanto a pasta é excluída.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        mutations.category.destroy.mutate(category.id, {
          onSuccess: () => {
            SweetAlert.close()
            refetch()
          },
          onError: () => {
            SweetAlert.fire({
              title: 'Erro ao excluir pasta',
              text: 'Ocorreu um erro ao excluir a pasta, tente novamente mais tarde.',
              icon: 'error',
            })
          },
        })
      }
    })
  }

  const onFileDelete = (id: number) => {
    mutations.document.destroy.mutate(id, {
      onSuccess: () => refetch(),
    })
  }

  const onSelect = (key: string) => setActiveKey(key)

  const onDragEnter = () => {
    if (!isDragging) {
      setIsDragging(true)
    }
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isDragging) {
      setIsDragging(true)
    }
  }

  const onDragLeave = () => {
    if (isDragging) {
      setIsDragging(false)
    }
  }

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = []

      for (const file of Array.from(e.dataTransfer.files)) {
        files.push({
          id: random(),
          file,
          document_category_id: activeKey,
          client_id: client!.id,
        })
      }

      setTempList(files)

      e.dataTransfer.clearData()
    }

    setIsDragging(false)
  }

  return (
    <div className="card">
      <div className="card-body">
        {client && client.id > 0 && (
          <Tab.Container activeKey={activeKey} onSelect={(key: any) => onSelect(String(key))}>
            <div className="row align-content-stretch">
              <div className="col-12 col-md-2">
                <Categories className="card">
                  <div className="card-body">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setDocumentCategory({
                        client_id: client?.id,
                      } as IDocumentCategory)}
                      disabled={documentCategory !== null}
                    >Adicionar pasta</button>

                    {documentCategory !== null && (
                      <>
                        <hr />

                        <form onSubmit={onCreate}>
                          <input
                            type="text"
                            name="name"
                            className={'form-control' + (client?.document_categories?.length > 0 ? ' mb-3' : '')}
                            onChange={handleCategoryChange}
                            onKeyDown={e => e.key === 'Escape' && onAbort()}
                            disabled={mutations.category.create.isLoading}
                            autoFocus
                          />
                        </form>
                      </>
                    )}

                    {client?.document_categories?.length > 0 && (
                      <hr />
                    )}

                    <Nav variant="pills" className="flex-column">
                      {client?.document_categories?.map(category => (
                        <Nav.Item key={`nav-${category.id}`}>
                          <Nav.Link className="d-flex justify-content-between align-items-center" eventKey={category.id}>
                            <span>{category.name}</span> <AiOutlineCloseCircle size={22} onClick={() => onCategoryDelete(category)} />
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </Categories>
              </div>

              <Panel className="col-12 col-md-10">
                <Tab.Content>
                  {client?.document_categories?.map(category => (
                    <Tab.Pane eventKey={category.id} key={`pane-${category.id}`}>
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-0">{category.name}</h5>
                          <small className="text-muted">
                            {category.documents.length} documento{category.documents.length === 1 ? '' : 's'}
                          </small>
                        </div>

                        <Files
                          className={'card-body p-0' + (isDragging ? ' dragging' : '')}
                          onDragEnter={onDragEnter}
                          onDragOver={onDragOver}
                          onDragLeave={onDragLeave}
                          onDrop={onDrop}
                        >
                          {category.documents.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table table-hover mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th>Arquivo</th>
                                    <th>Data de envio</th>
                                    <th>Anexar</th>
                                    <th className="text-end">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {category.documents.map(document => (
                                    <File document={document} key={`document-${document.id}`} onDelete={onFileDelete} />
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="alert alert-secondary mb-0 p-2 pe-3 ps-3 m-3">Nenhum documento encontrado.</div>
                          )}

                          <div className="upload-overlay">
                            <div className="content">
                              <span>
                                Arraste aqui para fazer o <strong>upload</strong>
                              </span>
                            </div>
                          </div>
                        </Files>
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Panel>
            </div>
          </Tab.Container>
        )}
      </div>
    </div>
  )
}

export default Documents
