import React, { useContext } from 'react'
import ClientContext from '@contexts/Client'
import { Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Main from './Tabs/Main/Main'
import Users from './Tabs/Users/Users'
import Notes from './Tabs/Notes/Notes'
import Documents from './Tabs/Documents/Documents'

const Client: React.FC<any> = () => {
  const { id, client, activeTab, setActiveTab } = useContext(ClientContext)

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h1 className="page-title mb-0">
          {id ? client?.trading_name : (
            <>
              {client?.trading_name && client.trading_name.length > 0 ? `Registrar cliente: ${client.trading_name}` : 'Registrar cliente'}
            </>
          )}
        </h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Início</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/clientes">Clientes</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {id ? client?.trading_name : 'Registrar cliente'}
            </li>
          </ol>
        </nav>
      </div>

      <Tabs activeKey={activeTab} onSelect={key => setActiveTab(key as string)}>
        <Tab eventKey="main" title="Cliente">
          <Main />
        </Tab>

        <Tab eventKey="users" title="Usuários" disabled={!client?.id}>
          <Users />
        </Tab>

        <Tab eventKey="notes" title="Avisos" disabled={!client?.id}>
          <Notes />
        </Tab>

        <Tab eventKey="documents" title="Documentos" disabled={!client?.id}>
          <Documents />
        </Tab>
      </Tabs>
    </>
  )
}

export default Client
