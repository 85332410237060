import styled from 'styled-components'

export const Main = styled.main`
  height: calc(100vh - 90px);
  position: relative;
  z-index: 2;

  > .row {
    height: 100%;

    > .sidebar {
      max-width: 280px;
      background-color: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      padding: 0;
    }
  }
`
