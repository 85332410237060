import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

enum LoginStep {
  Login = 'login',
  SecurityCode = 'security-code',
}

type LoginContextProps = {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  error: string|null
  setError: React.Dispatch<React.SetStateAction<string|null>>
  step: LoginStep
  setStep: React.Dispatch<React.SetStateAction<LoginStep>>
}

const LoginContext = createContext<LoginContextProps>({ } as LoginContextProps)

export const LoginProvider: React.FC<any> = () => {
  const [ email, setEmail ] = useState<string>('')
  const [ password, setPassword ] = useState<string>('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState<string|null>(null)
  const [ step, setStep ] = useState<LoginStep>(LoginStep.Login)

  return (
    <LoginContext.Provider
      value={{
        email,
        setEmail,
        password,
        setPassword,
        isLoading,
        setIsLoading,
        error,
        setError,
        step,
        setStep,
      }}
    >
      <Outlet />
    </LoginContext.Provider>
  )
}

export default LoginContext
