import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import IUser from '@interfaces/IUser'
import { useMutation, useQuery } from 'react-query'
import { find, create, update } from '@services/Admin'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { htmlErrorMessage } from '@helpers/Validation'

const Admin: React.FC<any> = () => {
  const [ admin, setAdmin ] = useState<IUser|null>(null)

  const { id }: any = useParams()
  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  const { isLoading, data: response, error, refetch } = useQuery(id, find, {
    enabled: Boolean(id),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })

  useEffect(() => {
    if (!isLoading && !error && response) {
      setAdmin(response.data)
    }
  }, [isLoading, error, response, setAdmin])

  const { mutate } = useMutation((data: IUser) => {
    if (data.id > 0) {
      return update(data)
    } else {
      return create(data)
    }
  })

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setAdmin({
    ...admin,
    [name]: value,
  } as IUser)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!admin)
      return

    SweetAlert.fire({
      title: id ? 'Atualizando administrador' : 'Registrando administrador',
      text: 'Aguarde enquanto o administrador é ' + (id ? 'atualizado' : 'registrado') + '...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    mutate(admin, {
      onSuccess: ({ data }) => SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Administrador ' + (id ? 'atualizado' : 'registrado') + ' com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then(() => navigate(`/administradores/${data.id}`)),
      onError: (e: any) => SweetAlert.fire({
        title: 'Erro!',
        html: htmlErrorMessage(e.response.data, 'Ocorreu um erro ao ' + (id ? 'atualizar' : 'registrar') + ' o administrador!'),
        icon: 'error',
      }),
    })
  }

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h1 className="page-title mb-0">
          {id ? admin?.name : (
            <>
              {admin?.name && admin.name.length > 0 ? `Registrar administrador: ${admin.name}` : 'Registrar administrador'}
            </>
          )}
        </h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Início</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/administradores">Administradores</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {id ? admin?.name : 'Registrar administrador'}
            </li>
          </ol>
        </nav>
      </div>

      <div className="card">
        <form onSubmit={onSubmit}>
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Nome:</label>
                  <input type="text" name="name" id="name" className="form-control" defaultValue={admin?.name} onChange={handleInputChange} required />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="email">E-mail:</label>
                  <input type="email" name="email" id="email" className="form-control" defaultValue={admin?.email} onChange={handleInputChange} required />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="password">Senha:</label>
                  <input type="password" name="password" id="password" className="form-control" onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="password_confirmation">Confirmação de senha:</label>
                  <input type="password" name="password_confirmation" id="password_confirmation" className="form-control" onChange={handleInputChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="submit" className="btn btn-primary pe-3 ps-3 btn-sm" disabled={isLoading}>
              {admin?.id ? 'Atualizar' : 'Salvar'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Admin
