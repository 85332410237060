import React, { useContext, useState } from 'react'
import IDocument from '@interfaces/IDocument'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import ClientContext from '@contexts/Client'
import { random } from '@helpers/Utils'
import INote from '@interfaces/INote'

type Props = {
  document: IDocument
  onDelete: (id: number) => void
}

const File: React.FC<Props> = ({ document, onDelete }: Props) => {
  const { client, setActiveTab, setNote } = useContext(ClientContext)

  const [ isDeleting, setIsDeleting ] = useState(false)

  const handleDelete = () => {
    setIsDeleting(true)
    onDelete(document.id)
  }

  const handleAttach = () => {
    setActiveTab('notes')
    setNote({
      id: -random(),
      send_email_notification: true,
      client_id: client?.id,
      documents: [
        document,
      ]
    } as INote)
  }

  return (
    <tr>
      <td>
        <div className="name">
          <a href={document.file as string} target="_blank" rel="noreferrer">{document.name}</a>
        </div>
      </td>

      <td style={{ width: 200 }}>
        {moment(document.created_at).format('DD/MM/YYYY [ás] HH:mm')}
      </td>

      <td style={{ width: 200 }}>
        <button className="btn btn-link p-0" onClick={handleAttach}>Anexar a um aviso</button>
      </td>

      <td style={{ width: 200 }} className="text-end">
        <a href={document.file as string} className="btn btn-outline-primary me-2 btn-sm" target="_blank" rel="noreferrer" download>Baixar</a>
        <button className="btn btn-danger btn-sm" onClick={handleDelete} disabled={isDeleting}>
          <span>Excluir</span> {isDeleting && <Spinner animation="border" size="sm" className="ml-2" />}
        </button>
      </td>
    </tr>
  )
}

export default File
